import React from "react"
import Navbar from "../components/Navbar"
import personImage from "../assets/images/p1.png"
import person2Image from "../assets/images/p2.JPG"
import person3Image from "../assets/images/p3.png"
import person4Image from "../assets/images/p4.JPG"
import person5Image from "../assets/images/p5.png"
import person6Image from "../assets/images/p6.png"
import person7Image from "../assets/images/p7.JPG"
import linkedinIcon from "../assets/images/linkedin.png"
import Footer from "../components/Footer"
import Teamcard from "../components/Teamcard"

import { useRef, useEffect } from "react"

const About = () => {
  const elementRef = useRef(null)



  return (
    <div>
      <Navbar />
      <section className="about-section-bg main-container h-52 md:h-80 mt-5 opacity-80">
        <div className="max-w-7xl mx-auto flex items-center justify-center h-full">
          <h1 className="font-medium text-4xl md:text-6xl text-white">
            Our Team
          </h1>
        </div>
      </section>

      <section className="main-container mt-10 md:mt-20">
        <div className="max-w-7xl mx-auto">
          <div className="container">
            <div className="row">
              <h2 className="text-lg md:text-2xl font-medium uppercase border-b-2 border-custom-blue w-fit">
                Meet Our Team
              </h2>
            </div>
          </div>
          <div className="container py-5">
            <div className="row">
              <Teamcard id="card1"
                cardImage={personImage}
                cardTitle='Ariel Benzakein'
                position="Co-Founder & CEO"
                desc='Ariel has over 25 years of experience leading all of the post-sale, customer-facing teams at tech companies, including Professional Services, Customer Success, Technical Support, and Account Management. He has led high-performing customer-facing teams at companies like Experian, PerkinElmer, and Donnelley Financial Solutions (DFIN), and has a track record of using proven playbooks to increase gross and net revenue retention. He is frequently asked to share his expertise in Customer Success on leadership panels and podcasts and has won the APPEALIE Award for Customer Success Leadership. Ariel graduated Summa Cum Laude from Arizona State University.'
              ></Teamcard>
              <Teamcard id="card2"
                cardImage={person2Image}
                cardTitle='Michael LoPresti'
                position="Co-Founder & CTO"
                desc="Michael has been building software of all shapes and sizes for over 25 years. After starting his career building trading platforms and analytics engines on Wall St (Barclays, Bank of America and others), Michael shifted towards startups and has scaled up several high-performing teams. His expertise in building strong team culture and reslient software consistently produces exceptional results. "
              ></Teamcard>
              <Teamcard id="card3"
                cardImage={person3Image}
                cardTitle='Alex Crooks'
                position="Software Engineer"
                desc="Alex is a software engineer whose experience includes influencer marketing platforms, automated retail, and healthcare microservices. 
                After working in Washington DC as a Congressional aide, Alex retrained in several cutting-edge programming languages and has been successful in conceptualizing and bringing web app products to market.
                A self-starter who loves a challenge, Alex's pivot from the political sphere allows for confident, empathetic leadership, lateral thinking, and thoughtfulness to create applications capable of seamlessly and consistently providing services to hundreds of thousands of users and major brands such as Walmart and Amazon.
                
        "
              ></Teamcard>
              <Teamcard id="card4"
                cardImage={person4Image}
                cardTitle='Margaret Hatch'
                position="Software Engineer"
                desc="With a career spanning across sales, customer success, and software
                engineering, Margaret's innate empathy for customers fuels her passion
                for creating products that address the deeper, underlying needs of the
                user. Having excelled within sales at LinkedIn/Microsoft and worked
                closely with production implementation and innovation in customer
                success at the hypergrowth unicorn, Miro, Margaret leverages her
                experience in these roles to inform the customer journey to spearhead
                the creation of innovative, customer-centric software products.
                 "
              ></Teamcard>
              <Teamcard id="card5"
                cardImage={person5Image}
                cardTitle='Arianna Banks'
                position="Software Engineer / UI Designer"
                desc="Arianna is a full-stack software engineer, using their extensive skill-set in social media presence, to build responsive and aesthetically appealing experiences. With everything they design, their aim is to strike perfection in both functionality and style. Building the intuitive user experiences of tomorrow is their passion."
              ></Teamcard>
              <Teamcard id="card6"
                cardImage={person6Image}
                cardTitle='Oleg Kaganovich'
                position="Advisor"
                desc="Oleg has over 20+ years of company-building experience as a 4x founder, operator, early-stage venture capital investor, advisor, and board member. His focus and core values around product, team, and mentorship have helped him launch and grow new ventures, equity-driven entrepreneurship centers, and venture funds in the U.S., Europe, and Asia. Oleg graduated with an MBA from Columbia Business School, and when not working with founders, he can be found studying Python for data science, learning to play bass guitar, and experimenting with sous vide cooking."
              ></Teamcard>
              <Teamcard id="card7"
                cardImage={person7Image}
                cardTitle='Vidhi Jain'
                position="Advisor"
                desc="Vidhi has 16+ years of experience managing and leading various Finance teams in Banking, Tech and Retail companies. She has played various impactful roles in Finance, led many debt and equity funding rounds, guided companies through multiple public earning cycles and go-private acquisition. She is currently the Global Treasurer at Qualtrics (a leading PE backed SaaS company) and also an advisor for Balance Cash Inc. She is an expert in managing optimal corporate investments, cash forecasts, investor and banking relationships, finance planning and budgeting cycles. She is a frequent speaker at the Association of Finance Professionals and EuroFinance conferences. She is also an advocate of DE&I at work and helps to bring inclusivity for all communities. "
              ></Teamcard>
            </div>
          </div>


        </div>
      </section>

      <Footer />
    </div>
  )
}

export default About
