import React from "react"
import arrowImage from "../assets/images/arrow.png"

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <footer className="relative main-container mt-10 bg-slate-900">
      <button
        onClick={handleScrollToTop}
        className="absolute top-[-17px] md:top-[-20px] left-1/2 bg-custom-orange p-2 border-2 md:border-4 border-white rounded-full transform -translate-x-1/2 animate-bounce-y-2"
      >
        <img
          src={arrowImage}
          width={24}
          height={24}
          alt="arrow-up"
          className="w-4 md:w-6"
        />
      </button>

      <div className="max-w-7xl mx-auto py-6">
        <div className="flex flex-col md:flex-row items-start justify-between mt-4">
          <div>
            <h3 className="text-custom-orange text-sm md:text-base font-medium">
              Email Address
            </h3>
            <a href="mailto:info@floworksoftware.com">
              <p className="text-gray-200 text-xs md:text-sm mt-1 md:mt-2">
                info@floworksoftware.com
              </p>
            </a>
          </div>
          <p className="text-gray-200 text-[10px] md:text-xs mt-4 md:mt-0">
          Copyright 2024 - Flowork Corporation-All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
