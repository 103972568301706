import React from "react"
import ReactDOM from "react-dom/client"
import "./assets/libs/simplebar/dist/simplebar.min.css"
import "./assets/libs/simplebar/dist/simplebar.min.css"
import "./assets/libs/bootstrap-icons/font/bootstrap-icons.min.css"
import "./assets/libs/scrollcue/scrollCue.css"
import "./assets/fonts/css/boxicons.min.css"
import "./index.css"
import "./assets/css/theme.min.css"
import App from "./App"
import { HashRouter as Router} from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  // <React.StrictMode>
    <Router>
      <App />
    </Router>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
