import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import HubspotForm from "react-hubspot-form"
import loadingSpinner from "../assets/images/loading-spinner.svg"

const MoreInfo = () => {

  return (
    <div>
      <Navbar />
      <section className="moreinfo-section-bg main-container h-52 md:h-80 mt-5 opacity-80">
        <div className="max-w-7xl mx-auto flex items-center justify-center h-full">
          <h1 className="font-medium text-lg md:text-2xl text-white text-center">Want to learn more about Flowork?  Fill out the form below and someone will be in contact with you.</h1>
        </div>
      </section>
      <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div id="hubspot-form" className="min-h-[400px]">
            <HubspotForm
              region="na1"
              portalId="40020612"
              formId="c731e6bd-93df-4af7-b121-9096a104753e"
              loading={<div className="flex items-center justify-center"><img src={loadingSpinner} alt="spinner" width={80}/></div>}
            />
          </div>
      </div>
    </div>
</div>
      <Footer />
    </div>
  )
}

export default MoreInfo
