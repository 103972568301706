import Home from "./pages/Home"
import About from "./pages/About"
import MoreInfo from "./pages/MoreInfo"
import Customerportal from "./pages/Customerportal"
import Customersuccess from "./pages/Customersuccess"
import Onboarding from "./pages/Onboarding"
import Support from "./pages/Support"
import { Routes, Route } from "react-router-dom"

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path="/customer-portal" element={<Customerportal />} exact />
      <Route path="/customer-success" element={<Customersuccess />} exact />
      <Route path="/onboarding-professional-services" element={<Onboarding />} exact />
      <Route path="/technical-support" element={<Support />} exact />
      <Route path="/team" element={<About />} exact />
      <Route path="/info" element={<MoreInfo/>} exact />
    </Routes>
  )
}

export default App
