import React from "react"
import Navbar from "../components/Navbar"
import personImage from "../assets/images/pro4.jpeg"
import robo from "../assets/images/robo.png"
import Footer from "../components/Footer"
import { Link } from "react-router-dom"

import { useRef, useEffect } from "react"

const Support = () => {
  const elementRef = useRef(null)



  return (
    <div>
      <Navbar />
      <section className="py-xl-9 py-5 bg-light">
            <div className="container">
            <div className="row">
                    <div className="col-xl-5 col-md-5 align-items-center d-flex" data-cue="fadeIn" data-show="true" style={{ animationName: 'fadeIn', animationDuration: '600ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                        <div className="mb-xl-7 mb-5">
                        <div className="d-flex justify-content-start">
              <span className="bg-primary bg-opacity-10 text-primary border-primary border p-2 fs-6 rounded-pill lh-1 d-flex align-items-center">
                <span className="badge bg-primary">Home</span>
                <span className="ms-2">Solutions</span>
                <span className="ms-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1 8.89181C1 8.7592 1.05268 8.63202 1.14645 8.53825C1.24021 8.44448 1.36739 8.39181 1.5 8.39181H13.293L10.146 5.24581C10.0521 5.15192 9.99937 5.02458 9.99937 4.89181C9.99937 4.75903 10.0521 4.63169 10.146 4.53781C10.2399 4.44392 10.3672 4.39117 10.5 4.39117C10.6328 4.39117 10.7601 4.44392 10.854 4.53781L14.854 8.53781C14.9006 8.58425 14.9375 8.63943 14.9627 8.70017C14.9879 8.76092 15.0009 8.82604 15.0009 8.89181C15.0009 8.95757 14.9879 9.02269 14.9627 9.08344C14.9375 9.14418 14.9006 9.19936 14.854 9.24581L10.854 13.2458C10.7601 13.3397 10.6328 13.3924 10.5 13.3924C10.3672 13.3924 10.2399 13.3397 10.146 13.2458C10.0521 13.1519 9.99937 13.0246 9.99937 12.8918C9.99937 12.759 10.0521 12.6317 10.146 12.5378L13.293 9.39181H1.5C1.36739 9.39181 1.24021 9.33913 1.14645 9.24536C1.05268 9.15159 1 9.02441 1 8.89181Z" fill="#ff7a59"></path>
                  </svg>
                </span>
              </span>
            </div>
            <h1 className="font-medium text-4xl text-capitalize md:text-start md:text-4xl display-3 lg:text-5xl max-w-lg leading-snug md:leading-[44px] lg:leading-[56px]">
            Technical <br></br><span className="font-bold text-custom-orange"> Support
</span>
 {/* SAVE UP TO{" "}
                <span className="font-bold text-custom-orange">2/3</span>
                <br />
                of your current software spend */}
              </h1>
             
              <Link to="/info">
          <button className="px-4 py-2 text-sm md:text-lg rounded-md bg-custom-orange text-white hover:cursor-pointer hover:bg-[#e2603f] transition-all duration-75 ease-in">
            More Info
          </button>
        </Link>   </div>
                    </div>
                    <div className="col-lg-7 mb-5" data-cue="zoomIn" data-show="true" style={{ animationName: 'zoomIn', animationDuration: '600ms', animationTimingFunction: 'ease', animationDelay: '200ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                <div className="d-flex gap-lg-4 gap-2 justify-content-center">
                    <div className="my-5">
                        <img src={personImage} alt="team" className="w-[100%]  md:w-[90%]  border border-custom-orange rounded-lg shadow-2xl skew-y-6 hover:skew-y-0 transition-skew duration-500 ease-in-out animate-fade-right overflow-hidden" />
                    </div>
                    {/* <div>
                        <img src="./assets/images/landings/it-company/team-long-img-2.jpg" alt="team" className="img-fluid rounded-3" />
                    </div>
                    <div className="mt-5">
                        <img src="./assets/images/landings/it-company/team-long-img-3.jpg" alt="team" className="img-fluid rounded-3" />
                    </div> */}
                </div>
            </div>
                </div>
               
                <div className="row g-4" data-cue="fadeIn" data-show="true" style={{ animationName: 'fadeIn', animationDuration: '600ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                <div className="col-lg-6 col-md-6 col-12">
            <div className="card card-lift h-100" data-cue="zoomIn" data-duration="500" data-show="true" style={{ animationName: 'zoomIn', animationDuration: '500ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                <div className="card-body p-5">
                    <div className="d-lg-flex">
                        <div className="p-3 icon-xl icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="currentColor" className="bi bi-vector-pen text-primary" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M10.646.646a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1 0 .708l-1.902 1.902-.829 3.313a1.5 1.5 0 0 1-1.024 1.073L1.254 14.746 4.358 4.4A1.5 1.5 0 0 1 5.43 3.377l3.313-.828L10.646.646zm-1.8 2.908-3.173.793a.5.5 0 0 0-.358.342l-2.57 8.565 8.567-2.57a.5.5 0 0 0 .34-.357l.794-3.174-3.6-3.6z"></path>
                                <path fillRule="evenodd" d="M2.832 13.228 8 9a1 1 0 1 0-1-1l-4.228 5.168-.026.086.086-.026z"></path>
                            </svg>
                        </div>
                        <div className="ms-lg-5 mt-4 mt-lg-0">
                            <div className="mb-4">
                                <h3>Single Screen Reporting</h3>
                                <p className="mb-0">Get the big picture of the status of your support desk. Advanced dashboard shows
everything you need in one place, such as tickets in queue, average time to resolution,
CSAT, and much more.</p>
                            </div>

                            <Link to="/info" className="icon-link icon-link-hover">More Info
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                </svg></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
            <div className="card card-lift h-100" data-cue="zoomIn" data-duration="500" data-show="true" style={{ animationName: 'zoomIn', animationDuration: '500ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                <div className="card-body p-5">
                    <div className="d-lg-flex">
                    <div className="p-3 icon-xl icon-shape rounded bg-success bg-opacity-10 border border-success-subtle">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-braces-asterisk text-success" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.114 8.063V7.9c1.005-.102 1.497-.615 1.497-1.6V4.503c0-1.094.39-1.538 1.354-1.538h.273V2h-.376C2.25 2 1.49 2.759 1.49 4.352v1.524c0 1.094-.376 1.456-1.49 1.456v1.299c1.114 0 1.49.362 1.49 1.456v1.524c0 1.593.759 2.352 2.372 2.352h.376v-.964h-.273c-.964 0-1.354-.444-1.354-1.538V9.663c0-.984-.492-1.497-1.497-1.6ZM14.886 7.9v.164c-1.005.103-1.497.616-1.497 1.6v1.798c0 1.094-.39 1.538-1.354 1.538h-.273v.964h.376c1.613 0 2.372-.759 2.372-2.352v-1.524c0-1.094.376-1.456 1.49-1.456v-1.3c-1.114 0-1.49-.362-1.49-1.456V4.352C14.51 2.759 13.75 2 12.138 2h-.376v.964h.273c.964 0 1.354.444 1.354 1.538V6.3c0 .984.492 1.497 1.497 1.6ZM7.5 11.5V9.207l-1.621 1.621-.707-.707L6.792 8.5H4.5v-1h2.293L5.172 5.879l.707-.707L7.5 6.792V4.5h1v2.293l1.621-1.621.707.707L9.208 7.5H11.5v1H9.207l1.621 1.621-.707.707L8.5 9.208V11.5h-1Z"></path>
                                 </svg>
                              </div>
                        <div className="ms-lg-5 mt-4 mt-lg-0">
                            <div className="mb-4">
                                <h3>Easy to Use, No Bloat</h3>
                                <p className="mb-0">Just the features you need, and nothing you don’t, in order to run a successful Help Desk
for your customers. Visual interface is extremely intuitive for your Support Analysts; little to
no training is required.</p>
                            </div>

                            <Link to="/info" className="icon-link icon-link-hover">More Info
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                </svg></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
            <div className="card card-lift h-100" data-cue="zoomIn" data-duration="500" data-show="true" style={{ animationName: 'zoomIn', animationDuration: '500ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                <div className="card-body p-5">
                    <div className="d-lg-flex">
                    <div className="p-3 icon-xl icon-shape rounded bg-warning bg-opacity-10 border border-warning-subtle">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-palette2 text-warning" viewBox="0 0 16 16">
                                    <path d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 .5.5v5.277l4.147-4.131a.5.5 0 0 1 .707 0l3.535 3.536a.5.5 0 0 1 0 .708L10.261 10H15.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H3a2.99 2.99 0 0 1-2.121-.879A2.99 2.99 0 0 1 0 13.044m6-.21 7.328-7.3-2.829-2.828L6 7.188v5.647zM4.5 13a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zM15 15v-4H9.258l-4.015 4H15zM0 .5v12.495V.5z"></path>
                                    <path d="M0 12.995V13a3.07 3.07 0 0 0 0-.005z"></path>
                                 </svg>
                              </div>
                        <div className="ms-lg-5 mt-4 mt-lg-0">
                            <div className="mb-4">
                                <h3>Case Deflection</h3>
                                <p className="mb-0">Built-in Case Deflection searches your Knowledgebase as your customer enters their
ticket, suggesting help articles that might solve their issue. This greatly reduces the
number of support tickets that your customers will enter.</p>
                            </div>

                            <Link to="/info" className="icon-link icon-link-hover">More Info
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                </svg></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
            <div className="card card-lift h-100" data-cue="zoomIn" data-duration="500" data-show="true" style={{ animationName: 'zoomIn', animationDuration: '500ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                <div className="card-body p-5">
                    <div className="d-lg-flex">
                    <div className="p-3 icon-xl icon-shape rounded bg-info bg-opacity-10 border border-info-subtle">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-window-dock text-info" viewBox="0 0 16 16">
                                    <path d="M3.5 11a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm4.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"></path>
                                    <path d="M14 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h12ZM2 14h12a1 1 0 0 0 1-1V5H1v8a1 1 0 0 0 1 1ZM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2Z"></path>
                                 </svg>
                              </div>
                        <div className="ms-lg-5 mt-4 mt-lg-0">
                            <div className="mb-4">
                                <h3>Knowledgebase </h3>
                                <p className="mb-0">Create a repository of information about your product for your customers. The
knowledgebase is fully customizable and searchable.</p>
                            </div>
<Link to="/info" className="icon-link icon-link-hover">More Info
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                </svg></Link>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>





        
                </div>
                <div className="row align-items-center gy-5 mt-5">
            <div className="col-lg-5 pe-lg-6" data-cue="zoomIn" data-show="true" style={{ animationName: 'zoomIn', animationDuration: '600ms', animationTimingFunction: 'ease', animationDelay: '400ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                <div className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column gap-2">
                        <h3 className="mb-0">Advanced Journey Conductor
</h3>
                        <p className="mb-0">Flowork allows you to build complex customer journeys and events using a simple drag &
drop visual interface. For example, in just a few clicks you could create the following
scenario: when a ticket is open for “x” number of days, email the VP of Customer
Support.
 </p>
                    </div>
                    <div>
                    <Link to="/info" className="btn btn-primary">More Info</Link>                    </div>
                </div>
            </div>
            <div className="col-lg-7" data-cue="zoomIn" data-show="true" style={{ animationName: 'zoomIn', animationDuration: '600ms', animationTimingFunction: 'ease', animationDelay: '200ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                <div className="d-flex gap-lg-4 gap-2 justify-content-center">
                <div className="mt-5">
                        <img src={robo}  alt="team" className="w-[100%] md:w-[80%] transition-skew duration-500 ease-in-out animate-fade-right overflow-hidden" />
                    </div>
                    {/* <div>
                        <img src="./assets/images/landings/it-company/team-long-img-2.jpg" alt="team" className="img-fluid rounded-3" />
                    </div>
                    <div className="mt-5">
                        <img src="./assets/images/landings/it-company/team-long-img-3.jpg" alt="team" className="img-fluid rounded-3" />
                    </div> */}
                </div>
            </div>
          
          
        </div>
            </div>
        </section>




      <Footer />
    </div>
  )
}

export default Support
