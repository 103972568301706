import React from "react"
import logo from "../assets/images/logo.svg"
import { Link } from "react-router-dom"
import { useState } from "react"

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMobileMenuOpen((menu) => !menu)
  }

  const menuIcon = (
    <svg
      className={`w-6 h-6 text-black transition-transform duration-300 ${
        isMobileMenuOpen ? "open" : ""
      }`}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      {isMobileMenuOpen ? (
        <>
          <path d="M6 18L18 6M6 6l12 12" />
          <path d="M6 6l12 12M6 18L18 6" />
        </>
      ) : (
        <path d="M4 6h16M4 12h16M4 18h16" />
      )}
    </svg>
  )

  return (
    <div className="navbar pt-3 md:pt-4 main-container">
      <div className="w-100 mx-auto flex justify-content-between">
        <div className="flex w-full items-center justify-between">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              width={930}
              height={330}
              className="w-24 md:w-44 max-w-full"
            />
          </Link>
          <ul className="hidden md:flex text-sm md:text-base gap-6 font-semibold items-center">
          <li className="hover:cursor-pointer hover:text-custom-orange hover:border-b-2 border-custom-orange nav-item dropdown">
          <a className=" dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Solutions
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            
            <li><Link to="/customer-success" className="dropdown-item">Customer Success</Link></li>
            <li><Link to="/onboarding-professional-services" className="dropdown-item">Onboarding/Professional Services</Link></li>
            <li><Link to="/technical-support" className="dropdown-item">Technical Support</Link></li>
            <li><Link to="/customer-portal" className="dropdown-item">Customer Portal</Link></li>
             </ul>
        </li>
            
           
              <li className="hover:cursor-pointer hover:text-custom-orange hover:border-b-2 border-custom-orange nav-item">
              <Link to="/team"> Team </Link>
              </li>
           
            
              <li className=" nav-item px-4 py-2 rounded-md bg-custom-orange text-white hover:cursor-pointer hover:bg-[#e2603f] transition-all duration-75 ease-in">
              <Link to="/info" className="text-white"> More Info</Link> 
              </li>
         
          </ul>

          {/* Mobile Navigation */}

          <div className="md:hidden flex items-center">
            <button onClick={toggleMenu} className="outline-none z-30">
              {menuIcon}
            </button>
          </div>
        </div>
      </div>

      <div
        className={`${
          isMobileMenuOpen
            ? "translate-x-0 touch-none transition-all duration-300 ease-in"
            : "-translate-x-full"
        } min-h-screen w-screen fixed top-0 left-0 z-20 bg-[#ffffff] pt-3 main-container`}
      >
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            width={930}
            height={330}
            className="w-24 md:w-44 max-w-full"
          />
        </Link>
        <ul className="mt-10 flex flex-col gap-5">
        <li className="hover:cursor-pointer hover:text-custom-orange hover:border-b-2 border-custom-orange nav-item dropdown">
          <a className=" dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Solutions
          </a>
          <ul className="dropdown-menu position-relative" aria-labelledby="navbarDropdown">
            
            <li><Link to="/customer-success" className="dropdown-item">Customer Success</Link></li>
            <li><Link to="/onboarding-professional-services" className="dropdown-item">Onboarding/Professional Services</Link></li>
            <li><Link to="/technical-support" className="dropdown-item">Technical Support</Link></li>
            <li><Link to="/customer-portal" className="dropdown-item">Customer Portal</Link></li>
             </ul>
        </li>
            
           
              <li className="hover:cursor-pointer hover:text-custom-orange hover:border-b-2 border-custom-orange nav-item">
              <Link to="/team"> Team </Link>
              </li>
           
            
            
          <Link to="/info">
            <li className="w-fit px-2 py-2 text-sm mt-4 rounded-md bg-custom-orange text-white hover:cursor-pointer hover:bg-[#e2603f] transition-all duration-75 ease-in">
              More Info
            </li>
          </Link>
        </ul>
      </div>
    </div>
  )
}

export default Navbar
