import React from "react"
import Navbar from "../components/Navbar"
import productImage from "../assets/images/product.jpeg"
import tickMark from "../assets/images/orange-tick.png"
import Footer from "../components/Footer"
import { Link } from "react-router-dom"
import s1 from "../assets/images/a1.png"
import s2 from "../assets/images/a2.png"
import s3 from "../assets/images/a3.png"
import s4 from "../assets/images/a4.png"

const Home = () => {
  const customerSuccess = [
    "AI-based Health Scoring",
    "AI-based Recommendations Engine for CSMs",
    "Playbooks",
    "Success Planning",
    "Maturity Modeling",
    "Sophisticated Journey Conductor; create complex Customer Journeys",
    "See GRR and NRR Company-wide, Per Team, and Per CSM",
  ]

  const onBoardAndService = [
    "Assign Tasks to Customers",
    "Customer Portal",
    "Project Templates",
    "Quickly build simple or complex projects",
    "Easy Project Kanban Board",
    "AI-based Recommendations Engine for Project Managers",
  ]

  const technicalSupport = [
    "Ticketing System",
    "Knowledgbase",
    "Case Deflection",
    "Automatically search knowledbase for common issues",
    "Customer Portal",
    "Advanced Support tracking metrics",
    "AI-based Recommendations Engine for Support Analysts",
  ]

  return (
    <>
      <div className="bg-primary-container pb-4">
        <Navbar />
        <section id="hero" className="main-container mt-5">
          <div className="max-w-7xl flex flex-col-reverse items-center lg:items-start md:flex-row mx-auto justify-between">
            <div className="w-full md:w-1/2 mt-14 flex flex-col items-center md:items-start">
              <h1 className="font-medium text-4xl text-capitalize md:text-start md:text-4xl display-5 lg:text-5xl max-w-lg leading-snug md:leading-[44px] lg:leading-[56px]">
              One solution for your entire post-sale <span className="font-bold text-custom-orange"> customer lifecycle</span>
 {/* SAVE UP TO{" "}
                <span className="font-bold text-custom-orange">2/3</span>
                <br />
                of your current software spend */}
              </h1>
              <div className="grid grid-cols-1 mt-5 lg:mt-10 gap-y-5 gap-x-2 lg:gap-x-0">
                <div className="flex items-center gap-2">
                  <img src={tickMark} width={30} height={30} className="w-7 md:w-10 " alt="tick" />
                  <p className="font-semibold text-sm md:text-base mb-0">Onboarding/Professional Services</p>
                </div>

                <div className="flex items-center gap-2">
                  <img src={tickMark} width={30} height={30} className="w-7  md:w-10 " alt="tick" />
                  <p className="font-semibold text-sm md:text-base mb-0">Customer Success</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={tickMark} width={30} height={30} className="w-7 md:w-10 " alt="tick" />
                  <p className="font-semibold text-sm md:text-base mb-0">Technical Support</p>
                </div>
              </div>
              <Link to="/info">
          <button className="px-4 mt-4 py-2 text-sm md:text-lg rounded-md bg-custom-orange text-white hover:cursor-pointer hover:bg-[#e2603f] transition-all duration-75 ease-in">
            More Info
          </button>
        </Link>
            </div>
            <div className="w-full md:w-1/2 flex mt-6 md:mt-0 items-center justify-center lg:justify-end">
              <img
                src={productImage}
                alt="product-img"
                width={1600}
                height={1598}
                className="w-[75%] md:w-[90%] border border-custom-orange rounded-lg shadow-2xl skew-y-6 hover:skew-y-0 transition-skew duration-500 ease-in-out animate-fade-right overflow-hidden"
              />
            </div>
          </div>
        </section>
      </div>
     
<section className="my-xl-10 my-5">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-4 col-md-8 col-12">
                <div className="mb-6 mb-lg-0">
                <h2 className="font-medium text-2xl  md:text-start md:text-2xl h2 lg:text-4xl max-w-lg leading-snug md:leading-[44px] lg:leading-[48px]">
                Stop wasting money on multiple SaaS products - 
                 <span className="font-bold text-custom-orange">  Save up to 2/3rds  </span>
 {/* SAVE UP TO{" "}
                <span className="font-bold text-custom-orange">2/3</span>
                <br />
                of your current software spend */}of your software cost
              </h2>
              <p className="mt-4 md:mt-7 text-base  md:text-start md:text-lg">
              Get better insight into your customers and teams, all in one place.  Superior to kludgy integrations between multiple products.  Intuitive interface; little to no training needed for your employees.

              </p>

              <Link to="/info">
          <button className="px-4 mt-4 py-2 text-sm md:text-lg rounded-md bg-custom-orange text-white hover:cursor-pointer hover:bg-[#e2603f] transition-all duration-75 ease-in">
            More Info
          </button>
        </Link>
                </div>
            </div>
            <div className="col-lg-7 offset-lg-1 col-12">
                <div className="row">
                    <div className="col-md-6">
                        <div
                            className="card bg-success bg-opacity-10 border-0 mb-5">
                            <div className="card-body">
                                <div className="mb-4 img-50">
                                    <img src={s1}
                                        alt="html" />
                                </div>
                                <div className="mb-4">
                                    <h3>Customer Success</h3>
                                    <p className="mb-0">Features:
                                    <ol>
                                      <li>AI-Based Health Scoring
</li>
                                      <li>Results Led Growth Focused</li>
                                      <li>Maturity Modeling</li>
                                      <li>Many More...</li>

                                    </ol>
                                    </p>
                                </div>
<Link to="/customer-success"   className="icon-link icon-link-hover link-success">Read More
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="14" height="14"
                                        fill="currentColor"
                                        className="bi bi-arrow-right"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z">
                                        </path>
                                    </svg></Link>
                              
                            </div>
                        </div>
                        <div
                            className="card bg-warning bg-opacity-10 border-0 mb-5 mb-lg-0">
                            <div className="card-body">
                                <div className="mb-4 img-50">
                                    <img src={s3}
                                        alt="html" />
                                </div>
                                <div className="mb-4">
                                    <h3>Technical Support</h3>
                                    <p className="mb-0">Features:
                                    <ol>
                                      <li>Single Screen Reporting
</li>
                                      <li>Easy to Use, No Bloat</li>
                                      <li>Case Deflection</li>              
                                                              <li>Many More...</li>

                                    </ol>
                                    </p>
                                </div>
                                <Link to="/technical-support"    className="icon-link icon-link-hover link-warning">Read More
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="14" height="14"
                                        fill="currentColor"
                                        className="bi bi-arrow-right"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z">
                                        </path>
                                    </svg></Link>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-md-5">
                        <div
                            className="card bg-info bg-opacity-10 border-0 mb-5">
                            <div className="card-body">
                                <div className="mb-4 img-50">
                                    <img src={s2}
                                        alt="html" />
                                </div>
                                <div className="mb-4">
                                    <h3>Onboarding/Professional Services</h3>
                                    <p className="mb-0">Features:
                                    <ol>
                                      <li>Create & Build Projects
</li>
                                      <li>Assign Tasks to Customers</li>
                                      <li>Kanban-style Interface</li>
                                      <li>Many More...</li>
                                    </ol>
                                    </p>
                                </div>
                                <Link to="/onboarding-professional-services"   className="icon-link icon-link-hover link-info">Read More
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="14" height="14"
                                        fill="currentColor"
                                        className="bi bi-arrow-right"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z">
                                        </path>
                                    </svg></Link>
                              
                            </div>
                        </div>
                        <div
                            className="card bg-danger bg-opacity-10 border-0 mb-5 mb-lg-0">
                            <div className="card-body">
                                <div className="mb-4 img-50">
                                    <img src={s4}
                                        alt="html" />
                                </div>
                                <div className="mb-4">
                                    <h3>Customer Portal</h3>
                                    <p className="mb-0">Features:
                                    <ol>
                                      <li>Work with Support Tickets
</li>
                                      <li>Work on a Project</li>
                                      <li>Use the Knowledgebase</li>
                                      <li>Post in Peer-to-Peer Forums</li>
                                    </ol>
                                    </p>
                                </div>

                                <Link to="/customer-portal"   className="icon-link icon-link-hover link-danger">Read More
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="14" height="14"
                                        fill="currentColor"
                                        className="bi bi-arrow-right"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z">
                                        </path>
                                    </svg></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


      <Footer />
    </>
  )
}

export default Home
